//import "./onepager.css"
//import "./onepager_override.css"

import React, { CSSProperties } from 'react'
import { DSlidingBanner } from '../../dweb/DSlidingBanner'


export type TOnePagerConfig = {
    theme?:{
        backgroundColor?:string
    },
    // hero
    hero_section?: {
        hero_title: string,
        hero_subtitle: string,
        primary_btn_title?: string,
        primary_btn_link?: string,
        primary_btn_subtext?: string,
        hero_image_src?: string,
        hero_images_list?: string[]

    },
    // middle sections
    generic_section?: {
        text_with_image?: {
            subtitle: string,
            strip_image: any,
        },
        groupOfThree?: {
            title: string,
            subtitle: string,
            btn_text: string,
            btn_link: string
        }[]
    }[],
    // footer
    footer_section: {
        author_text: string,
        author_link: string,
        author_email: string,
        privacy_link: string,
        toc_link: string,
    },
    // all style override defines here. 
    bodyStyle?: CSSProperties,
    containerStyle?: CSSProperties,
    heroStyle?: CSSProperties,
    featureStyle?: CSSProperties
    footerStyle?: CSSProperties
}

export function OnePager({ config, extraElement }: { config: TOnePagerConfig, extraElement?: any }) {
    React.useLayoutEffect(() => {
        //@ts-ignore
        import('./onepager.css')
        //@ts-ignore
        import('./onepager_override.css')
    }, [])

    return (<div id="home" style={config.bodyStyle}>
        {extraElement}
        {config.hero_section ? <section className="hero hero--home" style={{backgroundImage: `linear-gradient(-180deg,  #30353e 30%,${config.theme?.backgroundColor||'#4d76ba'}  90%)`, ...config.heroStyle}}>
            <div className="container" style={config.containerStyle}>
                <h1>{config.hero_section.hero_title}</h1>
                <h2 style={{ marginTop: 15 }}>{config.hero_section.hero_subtitle}</h2>
                {config.hero_section.primary_btn_title ? <div className="aligncenter">
                    <p><a className="btn-primary" href={config.hero_section.primary_btn_link || "/dashboard"} rel="nofollow">{config.hero_section.primary_btn_title}</a></p>
                    <p><small>{config.hero_section.primary_btn_subtext}</small></p>
                </div> : null}
                {config.hero_section.hero_image_src ? <div className="group codeshare-examples">
                    <img src={config.hero_section.hero_image_src} />
                </div> : null}

                {config.hero_section.hero_images_list && <DSlidingBanner images={config.hero_section.hero_images_list} />}
            </div>
        </section> : null}
        {
            config.generic_section?.map((x, idx) => {
                return (<section className="features features--home" style={{ background:config.theme?.backgroundColor||'#4d76ba', ...config.featureStyle}}>
                    <div className="container">
                        <div className="aligncenter">
                            {x.text_with_image ? (
                                <>
                                    <p><small>{x.text_with_image.subtitle}</small></p>
                                    <p><img src={x.text_with_image.strip_image} alt="Some of our users" width="800" /></p>
                                </>
                            ) : null}

                            {x.groupOfThree ? (
                                <div className="group">
                                    {x.groupOfThree?.map((y, idy) => (
                                        <div className="span4">
                                            <h3>{y.title}</h3>
                                            <p>{y.subtitle}</p>
                                            <p><a className="btn-secondary btn-sm" href={y.btn_link} rel="nofollow">{y.btn_text}</a></p>
                                        </div>))
                                    }

                                </div>
                            ) : null}
                        </div>
                    </div>

                </section>)
            })
        }
        <footer className="style.aligncenter" style={config.footerStyle}>
            <div className="container">
                <p>Created by <a rel="noopener" target="_blank" href={config.footer_section.author_link}>{config.footer_section.author_text}</a>. For help and support shoot us an <a href={config.footer_section.author_email} rel="noopener" target="_blank">email</a>.</p>
                <p><a rel="noopener" target="_blank" href={config.footer_section.privacy_link}>Privacy Policy</a>&nbsp;•&nbsp;<a rel="noopener" target="_blank" href={config.footer_section.toc_link}>Terms of Service</a></p>
            </div>
        </footer>
    </div>)
}

