import { dcolors, dnetwork, TObject } from "corexxx";
import React, { useState } from "react";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DBox } from "../../libs/dweb/DBox";
import { DButton } from "../../libs/dweb/DButton";
import { DInputString } from "../../libs/dweb/DInputString";
import { DRow } from "../../libs/dweb/DRow";
import { DSearchButton } from "../../libs/dweb/DSearchButton";
import { DText } from "../../libs/dweb/DText";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function AdminToolsPage() {
    const [info, setInfo] = React.useState<TObject>({})
    let adminHook = useAdmin()
    let appCommand = useDAppCommand()
    const [super_user_token, set_super_user_token] = useState('')
    const [adminCode, setAdminCode] = useState('')
    const [pdata, setPData] = useState({ username: '', permission: '' })


    return (<DBasePage>
        <DBox style={{ marginBottom: 20 }}>
            <DText mode="title" style={{ marginBottom: 20 }}>Generate Super User access Token</DText>
            <DSearchButton value={adminCode} onTextChange={setAdminCode} placeholder="Enter Admin code" onBtnClick={async () => {
                appCommand.api.safeCall(async () => {
                    set_super_user_token('loading...')
                    let result = await dnetwork.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/utils/admin_tools/generate_super_user_token?admin_code=${adminCode}`)
                    set_super_user_token(result.out)
                })

            }} btn_text="Generate Super User token" />
            <DText style={{ padding: 10, color: 'white', background: dcolors.pallets.red800, marginTop: 20 }}>{super_user_token}</DText>
        </DBox>

        <DBox>
            <DText mode="title" style={{ marginBottom: 20 }}>Managing Permission Group</DText>
            <DRow>
                <DInputString value={pdata.username} onChange={x => setPData({ ...pdata, username: x })} label="username" style={{ margin: 10, flex: 1 }} />
                <DInputString value={pdata.permission} onChange={x => setPData({ ...pdata, permission: x })} label="permission" style={{ margin: 10, flex: 1 }} />
                <DButton
                    style={{ margin: 10, flex: 1 }}
                    text="Add Permission"
                    onClick={() => appCommand.api.safeCall(async () => {
                        let result = await dnetwork.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/simple_auth/add_permission?super_user_token=${super_user_token}&permission=${pdata.permission}&username=${pdata.username}`)

                    })} />
                <DButton
                    style={{ margin: 10, flex: 1 }}
                    text="remove Permission"
                    onClick={() => appCommand.api.safeCall(async () => {
                        let result = await dnetwork.getSimpleStore(`${appCommand.state.simpleStoreEndpoint}/api/simple_auth/remove_permission?super_user_token=${super_user_token}&permission=${pdata.permission}&username=${pdata.username}`)

                    })} />
            </DRow>
        </DBox>

    </DBasePage>)

}

