import React from "react";
import { DHook } from "../../libs/common_hooks/DHook";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DCodeDisplay } from "../../libs/dweb/DCodeDisplay";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function LiveLogPage() {
    const [code, setCode] = React.useState('')
    let adminHook = useAdmin()
    let appCommand = useDAppCommand()
    DHook.useAsyncEffect(async () => {
        setCode(await appCommand.api.get(appCommand.state.simpleStoreEndpoint + '/admin/log'))
    }, [appCommand.state.simpleStoreEndpoint])

    return (<DBasePage><DCodeDisplay code={code?.length > 0 ? code : 'Nothing found'} /></DBasePage>)

}

