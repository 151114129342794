import { Alert } from "@mui/material";
import { dtime } from "corexxx";
import { AccountScreen } from "../../dweb/DAccount";
import { useDAppCommand } from "../../dweb/DAppCommand";
import { DBox } from "../../dweb/DBox";
import { DCol } from "../../dweb/DCol";
import { StripePaymentDialog, TPaymentConfig } from "../../dweb/DPayment";
import { DResponsiveWindow } from "../../dweb/DResponsiveWindow";
import { DText } from "../../dweb/DText";

export const DefaultAccountScreen = ({ paymentConfig }: { paymentConfig?: TPaymentConfig }) => {
    let appCommand = useDAppCommand();
    return (
        <DResponsiveWindow>
            <DBox style={{ margin: '80px auto', minWidth: 420, alignItems: 'center', padding: 0, justifyContent: 'center' }}>
                <DCol style={{ backgroundColor: appCommand.getAppConfig()?.primary_color!!, width: '100%', padding: 15, alignItems: 'center', color: 'white' }}>
                    {appCommand.getAppConfig()?.app_name ? <DText style={{ color: 'inherit', fontSize: 25,  }}><span style={{opacity:0.9}}>Welcome to</span> <b>{appCommand.getAppConfig()!!.app_name!!}!</b></DText> : null}
                    {appCommand.getAppConfig()?.app_slogan ? <DText style={{ color: 'inherit', maxWidth: 300, fontSize: 12, textAlign: 'center', marginTop: 7, fontStyle:'italic' }}>{appCommand.getAppConfig()!!.app_slogan!!}</DText> : null}
                </DCol>

                <AccountScreen
                    config={{
                        style: { boxShadow: 'none', borderRadius: 0, width: 380, padding: 16 },
                        extra_link: [{ text: "Go to dashboard", href: "/dashboard" }],
                        extra_ele: (<>
                            {appCommand.getSubscription() ? (
                                <Alert style={{ marginBottom: 20 }}>
                                    <span>
                                        Thanks for having subscription. It will expire on {dtime.getFormattedDate(appCommand.getSubscription()!!.expiry_date) || "None"}.
                                        You can download the recept from <a href={appCommand.getSubscription()?.recept_url}>here</a>
                                    </span>
                                </Alert>
                            ) : null}
                            {paymentConfig && !appCommand.getSubscription() ? <StripePaymentDialog
                                style={{ marginBottom: 10 }}
                                config={paymentConfig}
                            /> : null}
                        </>)
                    }}
                />
            </DBox>
        </DResponsiveWindow>
    );
};
