import { TObject, dtime } from "corexxx";
import { DHook } from "../../libs/common_hooks/DHook";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DCol } from "../../libs/dweb/DCol";
import { DashboardTiles } from "../../libs/dweb/DDashboard";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function HealthPage() {
    var adminHook = useAdmin()
    const appCommand = useDAppCommand()
    let infoHook = DHook.useNetwork(appCommand.state.simpleStoreEndpoint + '/info', { success_msg: "Connected to SimpleStore", error_msg: "Not able to connect" })
    let extraData = DHook.useObject<TObject>({ errorCount: -1, sevCount: -1 })
    DHook.useAsyncEffect(async () => {
        let errorCount = (await appCommand.api.getSimpleStore(appCommand.state.simpleStoreEndpoint + '/api/crash_log/count')).out as number
        extraData[1].set('errorCount', errorCount)
        let sevCount = (await appCommand.api.getSimpleStore(appCommand.state.simpleStoreEndpoint + '/api/sev_manager/count')).out as number
        extraData[1].set('sevCount', sevCount)
    }, [])
    //debugger

    return (<DBasePage>
        {infoHook.state.data ? (<DCol>
            <DashboardTiles data={[
                { title: 'Deployment Mode', value: infoHook.state.data.isProd ? 'Prod' : 'Debug', errorColor: infoHook.state.data.isDebug },
                { title: 'Up Time', value: infoHook.state.data.runtime.substring(0, 7) + 'Min', errorColor: false },
                { title: 'Time At Server', value: dtime.getTimeOnlyFromISO(infoHook.state.data.timestamp), errorColor: false },
                { title: 'Local DB Connection', value: 'OK', errorColor: false },
                { title: 'Remote DB Connection', value: 'OK', errorColor: false },
                {   title: 'CPU Utilization',
                     value: `${infoHook.state.data.pm2_logs?.[0]?.monit?.cpu }% `,
                     subtext:`${((infoHook.state.data.pm2_logs?.[0]?.monit?.memory ||1)/ 1024 / 1024).toFixed(2)} MB`,
                      errorColor: false 
                    },
                {   title: 'Heap Utilization', 
                    value: `${infoHook.state.data.pm2_logs?.[0]?.pm2_env?.axm_monitor["Heap Usage"]?.value || '-'}${infoHook.state.data.pm2_logs?.[0]?.pm2_env?.axm_monitor["Heap Usage"]?.unit || '-'}`, 
                    errorColor: false,
                    subtext:`${infoHook.state.data.pm2_logs?.[0]?.pm2_env?.axm_monitor["Used Heap Size"]?.value || '-'}${infoHook.state.data.pm2_logs?.[0]?.pm2_env?.axm_monitor["Used Heap Size"]?.unit || '-'}`, 
                 
                },
                {
                    title: 'Node Disk Utilization',
                    value: infoHook.state.data.disk.percentageFreeSpace,
                    errorColor: true,
                    subtext: `Total: ${infoHook.state.data.disk.totalDiskSpace}, Free: ${infoHook.state.data.disk.freeDiskSpace}`
                },
                { title: 'SEV Count', value: `${extraData[0].sevCount || 0}`, errorColor: false, link: appCommand.state.simpleStoreEndpoint + '/api/sev_manager/' },
                { title: 'Runtime exception Count', value: `${extraData[0].errorCount || 0}`, errorColor: false, link: appCommand.state.simpleStoreEndpoint + '/api/crash_log/' },
            ]} />
        </DCol>) : null}
    </DBasePage>)
}

