import { DHook } from "../common_hooks/DHook";
import { useDAppCommand } from "./DAppCommand";
import { DLoadingUI } from "./DLoadingUI";
import { DText } from "./DText";

// Adding this compoemnet ensure that the page is loaded when we have a login
export function DNeedsLogin({children}:{children:any}){
        const appCommand = useDAppCommand()
        DHook.useAsyncEffect(async () => {
            if (!appCommand.state.accountState?.auth_token_) {
                appCommand.api.navigate('/account')
            } else {
                if (await appCommand.api.checkLogin() == false) {
                    appCommand.logout();
                    appCommand.api.navigate('/account')
                }
            }
        }, [])
        return appCommand.state.accountState?.auth_token_?children:<DText>Checking your login state, Redirecting to login. If not loaded please refresh</DText>;
}