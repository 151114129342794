// thsi wil controsl any hooks and other non UI releated code

import { dlog, dnetwork, drandom, TObject } from "corexxx";
//import { FirebaseApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import React from "react";
import ReactGA from "react-ga4"; // note that react-ga will not work
import _ from "underscore";
import { SimpleStoreClient } from "../common_ts/SimpleStoreClient";
import { TAppConfig } from "../dweb/DAppCommand";
const crypto = require("xor-crypt");
export namespace DWebTS {
  export type TGlobalData = {
    firebase_app?: FirebaseApp;
    firebase_storage?: FirebaseStorage;
    firebase_database?: Database
  };
  export const globalData: TGlobalData = {};
  var kAppConfig: TAppConfig | undefined = undefined;

  export function initAppTs(appConfig: TAppConfig) {
    kAppConfig = appConfig;
    if (appConfig.firebase_config && !globalData.firebase_app) {
      globalData.firebase_app = initializeApp(appConfig.firebase_config);
      globalData.firebase_storage = getStorage(globalData.firebase_app);
      globalData.firebase_database = getDatabase(globalData.firebase_app);
      const analytics = getAnalytics(globalData.firebase_app);
      dlog.d("Firebase inited");
    }
  }

  export const useEffectAsync = (func: any, deps: any[]) =>
    React.useEffect(() => {
      (async () => {
        await func();
      })();
    }, deps);
  // coomoon
  export function printStack() {
    var e = new Error();
    console.log(e.stack);
  }
  export function fixNumber(num?: number) {
    if (!num) {
      return 0;
    }
    return parseFloat(num.toFixed(2));
  }
  export function deepCopy(obj: TObject): TObject {
    return { ...obj };
    //return JSON.parse(JSON.stringify(obj)); // DONOT USE IT it works for file object
  }
  // Function will ask for confirmation and do stuff
  export function showConfirmationAndDo(func: () => void, text?: string) {
    if (confirm(text || "Confirm this action") == true) {
      func();
    }
  }

  export function decode(input: any): any {
    let d = crypto(input, 10);
    return JSON.parse(d) as TObject[];
  }

  export function encode(inp: TObject): string {
    let d = JSON.stringify(inp);
    return crypto(d, 10);
  }

  // upload to firebase
  export type TFileConfig = { user_id: string, file_name?: string; maxSizeKB?: number }

  function check_file_rule(files: any[], config: TFileConfig) {
    // make a check first for all files. 
    if (!globalData.firebase_storage) {
      throw (Error("Stoarge is not setup for this app."));
    }
    ee(config?.user_id, "You muast have a vlaid user id")
    if (!config?.user_id || config.user_id == '') {
      throw (Error("You must be logged in to upload a file"))
    }
    files.forEach((file: any) => {
      if (!file) {
        throw (Error("No file found"));
      }
      if (file.size / 1024 > (config?.maxSizeKB ? config.maxSizeKB : 512)) {
        throw (Error(`File size is more than ${config?.maxSizeKB || 512}KB. Please reduce size and upload`));
      }
    })
  }

  export function uploadAFile(file: any, config: TFileConfig): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        check_file_rule([file], config)
      } catch (e) {
        reject(e)
      }
      const debug = DWebTS.__DEV__ ? 'debug/' : ''
      const default_name = 'F' + drandom.getRandomId(3).toUpperCase() + '_' + file.name
      const path = `/files/${debug}${ee(kAppConfig?.app_name)}/${ee(config?.user_id)}/${config?.file_name ? config?.file_name + "." + file.name.split(".").pop() : default_name}`;
      dlog.d('path to upload' + path)
      const storageRef = ref(globalData.firebase_storage!!, path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100); // update progress
          //setPercent(percent);
        },
        (err) => {
          reject(err);
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            resolve(url);
          });
        }
      );
    });
  }

  // upload files to firebase but having restriction check before hand
  export async function uploadFiles(files: any[], config: TFileConfig): Promise<string[]> {
    check_file_rule(files, config)
    return await Promise.all(files.map(async (x) => await uploadAFile(x, config)))
  }

  // ensure the script is loaded
  export function loadScript(src: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        dlog.d("Loaded script:" + src);
        resolve(true);
      };
      script.onerror = () => {
        reject("Not able to load script");
      };
      document.body.appendChild(script);
    });
  }

  // ensure the script is loaded
  export function loadCSS(src: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("link") as any;
      script.rel = "stylesheet";
      script.href = src;
      script.onload = () => {
        dlog.d("Loaded css:" + src);
        resolve(true);
      };
      script.onerror = () => {
        reject("Not able to load css");
      };
      document.head.appendChild(script);
    });
  }

  // check dev
  export const __DEV__ = document.domain === "localhost";

  // Rozor pay api
  const RAZOR_PAY_URL = "https://simplestore.dipankar.co.in/api/remote_smb_manifest/razorpay_create_order";
  export async function displayRazorpay(
    config: { public_key: string; prefill?: { name?: string; email?: string; phone_number?: string } },
    paymentData: { amount: number; currency: "INR"; smb_id: string }
  ) {
    await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    const orderData = await dnetwork.postSimpleStore(RAZOR_PAY_URL, { payload: encode(paymentData) });
    let data = decode(orderData.out);
    return new Promise((resolve, reject) => {
      const options = {
        key: config.prefill,
        currency: data.currency,
        amount: data.amount.toString(),
        order_id: data.id || data.order_id,
        name: "Donation",
        description: "Thank you for nothing. Please give us some money",
        image: "http://localhost:1337/logo.svg",
        handler: function (resp: TObject) {
          resolve(resp);
        },
        prefill: config.prefill,
      };
      const paymentObject = new (window as any).Razorpay(options);
      paymentObject.open();
    });
  }

  // Analytice wrapper whcihc use google analytices
  export const danalytics = {
    init: (config: any) => {
      ReactGA.initialize(config);
    },
    pageview: (page: string) => {
      ReactGA.pageview(page);
    },
    report_action: (action: string) => {
      ReactGA.event({
        category: "User",
        action: action.toLowerCase(),
      });
    },
    report_error: (e: Error) => {
      ReactGA.event({
        category: "Exception",
        action: e.message,
        label: e.stack,
      });
    },
    report_fetal: (e: Error) => {
      if (e.stack) {
        SimpleStoreClient.Get().reportCrash(e.message, e.stack)
      } else {
        dlog.ex(e)
      }
      ReactGA.event({
        category: "Fetal",
        action: e.message,
        label: e.stack,
      });
    },
  };

  export function getDiffInYearFromToday(dateInIso: string): number {
    // @ts-ignore
    return ((new Date() as Date) - new Date(dateInIso)) / 3600e3 / 24 / 365;
  }


  // Ensure Exist --> Convert a not null to null 
  // You should use this rather using !!
  export function ee(x?: any, error?: string): any {
    if (x == null || x == undefined) {
      throw new Error('ensureExist failed !!' + (error || ''))
    }
    return x
  }

  export function getArray(str?: any): string[] {
    if (!str) {
      return []
    }
    if (_.isString(str)) {
      return str.split(',').map(x => x.trim()).filter(x => x.length > 0)
    }
    if (_.isArray(str)) {
      return str;
    }
    // discut any other payload
    return []
  }

  export function convertFileName(obj: string) {
    return obj
      .toLowerCase()
      .split(" ")
      .filter((x) => x.length > 0)
      .join("_");
  }
  // this will return a lazyView 
  export function makeLazyView(component: Function) {
    return async () => ({
      default: component
    })
  }

  export function downloadAsFile(scriptContent: string, name: string) {
    // Create a Blob (Binary Large Object) with the script content
    const blob = new Blob([scriptContent], { type: 'application/javascript' });

    // Create a link element
    const link = document.createElement('a');

    // Set the link's attributes
    link.href = window.URL.createObjectURL(blob);
    link.download = name;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  //console.log(currencyConversion(100, 'INR', 'USD')); // Converts 100 INR to USD
  //console.log(currencyConversion(50, 'USD', 'GBP'));  // Converts 50 USD to GBP
  export type TCurrency = 'INR' | 'USD' | 'GBP'
  export function currencyConversion(num: number, fromCur: TCurrency, toCur: TCurrency): number {
    // Define exchange rates with respect to USD as a base
    const exchangeRates: Record<TCurrency, number> = {
      INR: 84.5, // Example: 1 USD = 82.5 INR
      USD: 1,    // 1 USD = 1 USD
      GBP: 0.80  // Example: 1 USD = 0.75 GBP
    };

    // If fromCur or toCur is invalid, return NaN or handle error
    if (!exchangeRates[fromCur] || !exchangeRates[toCur]) {
      throw new Error(`Invalid currency: ${fromCur} or ${toCur}`);
    }

    // Convert the amount to USD first
    const amountInUSD = num / exchangeRates[fromCur];

    // Convert USD to the target currency
    const convertedAmount = amountInUSD * exchangeRates[toCur];

    return convertedAmount;
  }


  // Example usage:
//console.log(getCurrencySymbol('INR')); // Outputs: ₹
//console.log(getCurrencySymbol('USD')); // Outputs: $
//console.log(getCurrencySymbol('GBP')); // Outputs: £
  export function getCurrencySymbol(currency: TCurrency): string {
    const currencySymbols: Record<TCurrency, string> = {
      INR: '₹',
      USD: '$',
      GBP: '£'
    };
  
    if (!currencySymbols[currency]) {
      throw new Error(`Invalid currency: ${currency}`);
    }
  
    return currencySymbols[currency];
  }


}
