import { Button } from "@mui/material"
import { TVoidCalBack } from "corexxx"
import { CSSProperties } from "react"
import { useDAppCommand } from "./DAppCommand"
import { DImage } from "./DImage"
import { DPopUpMenu } from "./DPopUpMenu"
import { DRow } from "./DRow"
import { DSpace } from "./DSpace"

// Mostly used by single app just add this header to the top.
export function PageHeaderMenu({ style, logo, rightDropDownActions, leftNavigationList, logoStyle }: 
    { logoStyle?: CSSProperties, style?: CSSProperties, logo: any, rightDropDownActions?: { text: string, href?: string, onClick?: TVoidCalBack }[], leftNavigationList?:{text:string, href:string, children?:{text:string, href:string}[]}[]}) {
    let appCommand = useDAppCommand()
    return <DRow center style={{ alignItems: 'center', zIndex: 1, color: 'white', background: appCommand.getAppConfig()?.primary_color, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, position: 'fixed', top: 0, right: 0, left: 0, ...style }}>
        <a href={"/"}>
            <DImage src={logo} style={{ height: 25,width:25, marginTop:7, ...logoStyle }} />
        </a>
    
        {leftNavigationList?(
            leftNavigationList?.map((x,idx)=>{
                 return x.children? <DPopUpMenu key={idx} btnStyle={{ color: 'white' }} btnText={x.text} actions={x.children}></DPopUpMenu>:<Button key={idx} style={{ color: 'white', marginLeft:10, textTransform:'capitalize' }} size="small" href={x.href} variant="text">{x.text}</Button>
            })
        ):null}
        <DSpace />
        {rightDropDownActions ? (appCommand.accountState?._id ? (
            <DPopUpMenu btnStyle={{ color: 'white' }} btnText={`Welcome ${appCommand.accountState.name}`} actions={[...rightDropDownActions,
            { text: 'Account Settings', href: '/account' },
            { text: 'Log Out', onClick: () => { appCommand.logout() } }
            ]}></DPopUpMenu>
        ) : (
            <DRow style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Button style={{ color: 'white' }} size="small" href="/account" variant="contained">Sign Up/ Sign In</Button>
            </DRow>
        )) : null}
    </DRow>
}