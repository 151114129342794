import { DSplitDataView } from "../../libs/dweb/DSplitDataView"
import { DBasePage } from "../App"

export function SevManager() {
    return <DBasePage>
        <DSplitDataView url="https://simplestore.dipankar.co.in/api/sev_manager" columns={[
            {
                field: 'title',
                headerName: 'title'
            },

            {
                field: 'count',
                headerName: 'Hits'
            },
            {
                field: 'last_reported',
                headerName: 'Last Reported',
                view_type: 'time'
            }
        ]}
            getDetailsText={obj => `<pre>${obj['stack']}</pre>`}
            buildSearchParams={x => ({ 'title': 'regex:' + x })}
        />
    </DBasePage>
}