import React, { CSSProperties } from "react";
import { Switch, FormControlLabel } from "@mui/material";

interface DSwitchInputProps {
  value: boolean; // Controls the state of the switch
  onChange: (value: boolean) => void; // Callback when the switch state changes
  label: string; // Label displayed next to the switch
  dark?:boolean;
  style?:CSSProperties;
}

export const DSwitchInput: React.FC<DSwitchInputProps> = ({ value, onChange, label, dark, style }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      style={{color:dark?'white':'black'}}
      control={
        <Switch
          checked={value}
          onChange={handleChange}
          style={style}
        />
      }
      label={label}
    />
  );
};

